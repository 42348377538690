import axios from "axios";
// export const BASE_URL = `http://anees-dev.appmaker.xyz:3002/webapp`;
export const BASE_URL = `https://standalone-app-api.appmaker.xyz/webapp`;

export const createProject = async ({ url, email }) => {
  try {
    const response = await axios.post(`${BASE_URL}/build`, {
      url,
      email,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
/**
 *
 * Status
 */
export const getStatus = async ({ appId }) => {
  try {
    const response = await axios.get(`${BASE_URL}/build/status?appId=${appId}`);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Build
 */

export const buildProject = async ({
  appName,
  appIcon,
  appId,
  isPaymentInProgress = false,
  package_name,
  enableShowToolBar,
  toolbarColor,
  toolbarTitleColor,
  splashIcon,
  enableAdMob,
  adMobAppId,
  adMobUnitId,
  enablePushNotification,
  firebaseConfigFile,
  fcmKey,
}) => {
  try {
    const response = await axios.post(`${BASE_URL}/build/build`, {
      appId,
      appIcon,
      appName,
      isPaymentInProgress,
      package_name,
      enableShowToolBar,
      toolbarColor,
      toolbarTitleColor,
      splashIcon,
      enableAdMob,
      adMobAppId,
      adMobUnitId,
      enablePushNotification,
      firebaseConfigFile,
      fcmKey,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Download
 */
export const downloadApp = async ({ appId }) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/complete/download?appId=${appId}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Upload file
 */

export const uploadImage = async (formData) => {
  try {
    const data = new FormData();
    data.append("file", formData.file);
    data.append("id", formData.id);
    const response = await axios.post(`${BASE_URL}/build/file-upload`, data);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
