import React, { Component } from "react";
import StepDots from "./components/StepDots";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import TooltipTrigger from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";
import { trackEvent } from '../../utlis/analytics';

import * as SETTINGS from "../../constants/Settings";
import * as ROUTES from "../../constants/routes";
import storage from "local-storage-fallback";

class ChooseMethod extends Component {
  render() {
    const url = storage.getItem("url");
    return (
      <div className="row">
        <div className="col-md-8 col-12">
          <StepDots active="choose-method" />

          <div className="step-box step-box-chooseMethod bg-white rounded-lg shadow p-5 position-relative">
            <form action="">
              <h4 className="text-secondary">Choose a method</h4>
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="choose-method mb-3">
                    <a
                      id="#self-create"
                      onClick={e => {
                        e.preventDefault();
                        trackEvent(
                          "create-selected-manual-option",
                          { url: url },
                          { send_immediately: true },
                          function () {
                            window.location.href =
                              `https://appmaker.xyz/woocommerce/create?manual=1&create-app=woocommerce&wooUrl=${url}`;

                          }
                        );
                      }}
                      href={`https://appmaker.xyz/woocommerce/create?manual=1&create-app=woocommerce&wooUrl=${url}`}
                      className="dashed-container d-flex text-decoration-none text-dark p-3"
                    >
                      <img
                        src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/04/dd710aaa-make-myself.png"
                        alt="choose method"
                      />
                      <h6 className="flex-grow-1 my-auto ml-3">
                        I want to build My App my self
                      </h6>
                    </a>

                    <TooltipTrigger
                      placement="bottom"
                      trigger="hover"
                      tooltip={({
                        getTooltipProps,
                        getArrowProps,
                        tooltipRef,
                        arrowRef,
                        placement
                      }) => (
                        <div
                          {...getTooltipProps({
                            ref: tooltipRef,
                            className: "tooltip-container bg-dark"
                          })}
                        >
                          <div
                            {...getArrowProps({
                              ref: arrowRef,
                              "data-placement": placement,
                              className: "tooltip-arrow"
                            })}
                          />
                          <div className="tooltip-body text-white">
                            <h6>DIY Model</h6>
                            <ul className="px-4">
                              <li>
                                <p className="mb-0">Do it yourself</p>
                              </li>
                              <li>
                                <p className="mb-0">
                                  Android & iOS app made by you
                                </p>
                              </li>
                              <li>
                                <p className="mb-0">App upload by yourself</p>
                              </li>
                              <li>
                                <p className="mb-0">Knowledgebase support</p>
                              </li>
                              <li>
                                <p className="mb-0">Current Plugin Supported</p>
                              </li>
                              <li>
                                <p className="mb-0">Blaze plan pricing</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    >
                      {({ getTriggerProps, triggerRef }) => (
                        <span
                          {...getTriggerProps({
                            ref: triggerRef,
                            className: "trigger learnmore"
                          })}
                        >
                          <i className="fas fa-exclamation-circle" />
                        </span>
                      )}
                    </TooltipTrigger>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="choose-method mb-3">
                    <a
                      id="#book-a-demo"
                      href="https://appmaker.xyz/book-a-demo/"
                      onClick={e => {
                        e.preventDefault();
                        trackEvent(
                          "create-selected-book-a-demo",
                          { url: url },
                          { send_immediately: true },
                          function () {
                            window.location.href =
                              `https://appmaker.xyz/book-a-demo/`;

                          }
                        );
                      }}
                      className="dashed-container d-flex text-decoration-none text-dark p-3"
                    >
                      <img
                        src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/04/0c22286d-experts-do.png"
                        alt="choose method"
                      />
                      <h6 className="flex-grow-1 my-auto ml-3">
                        Let our experts do it <br />
                        <small>(Book A Demo)</small>
                      </h6>
                    </a>

                    <TooltipTrigger
                      placement="bottom"
                      trigger="hover"
                      tooltip={({
                        getTooltipProps,
                        getArrowProps,
                        tooltipRef,
                        arrowRef,
                        placement
                      }) => (
                        <div
                          {...getTooltipProps({
                            ref: tooltipRef,
                            className: "tooltip-container bg-dark"
                          })}
                        >
                          <div
                            {...getArrowProps({
                              ref: arrowRef,
                              "data-placement": placement,
                              className: "tooltip-arrow"
                            })}
                          />
                          <div className="tooltip-body text-white">
                            <h6>Enterprise Model</h6>
                            <ul className="px-4">
                              <li>
                                <p className="mb-0">
                                  Book call and get app delivered
                                </p>
                              </li>
                              <li>
                                <p className="mb-0">
                                  Android & iOS app made by us
                                </p>
                              </li>
                              <li>
                                <p className="mb-0">
                                  App uploaded by us for you
                                </p>
                              </li>
                              <li>
                                <p className="mb-0">
                                  Email and Priority support
                                </p>
                              </li>
                              <li>
                                <p className="mb-0">
                                  Custom plugin integrations
                                </p>
                              </li>
                              <li>
                                <p className="mb-0">
                                  Full website compatibility
                                </p>
                              </li>
                              <li>
                                <p className="mb-0">
                                  Custom feature requirements
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    >
                      {({ getTriggerProps, triggerRef }) => (
                        <span
                          {...getTriggerProps({
                            ref: triggerRef,
                            className: "trigger learnmore"
                          })}
                        >
                          <i className="fas fa-exclamation-circle" />
                        </span>
                      )}
                    </TooltipTrigger>
                  </div>
                </div>
                <small className="ml-3">
                  Click on a method that convenient for you
                </small>
              </div>

              <div className="text-right">
                <Link
                  to={ROUTES.INITIAL}
                  className="text-decoration-none text-secondary"
                >
                  Go Back
                </Link>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-4 col-12 mt-5">
          <h5 className="text-primary font-weight-bold">
            <i className="far fa-comment-alt" /> FAQ
          </h5>
          {/* <p className="my-3">
            The App experts with over 5-year experience will know what best for
            your App also ensuring plugin compatibility or if you prefer to save
            a few dollars you can build the App on your own.
          </p> */}
          <div className="accordion" id="chooseMethodFAQ">
            <div className="card shadow-sm border-0 my-1 show">
              <div
                className="card-header p-0 bg-primary border-0 rounded"
                id="headingOne"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link text-decoration-none text-white"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Confused what to choose?
                  </button>
                </h5>
              </div>

              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#chooseMethodFAQ"
              >
                <div className="card-body">
                  The App experts with over 5-year experience will know what
                  best for your App also ensuring plugin compatibility or if you
                  prefer to save a few dollars you can build the App on your
                  own.
                </div>
              </div>
            </div>
            <div className="card shadow-sm border-0 my-1">
              <div
                className="card-header p-0 bg-primary border-0 rounded"
                id="headingTwo"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link text-decoration-none text-white"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Who will publish app on stores?
                  </button>
                </h5>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#chooseMethodFAQ"
              >
                <div className="card-body">
                  We will be providing proper documentation on how to publish
                  App or our App experts will publish your app on both the
                  stores using your own developer account.
                </div>
              </div>
            </div>
            <div className="card shadow-sm border-0 my-1">
              <div
                className="card-header p-0 bg-primary border-0 rounded"
                id="headingThree"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link text-decoration-none text-white"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    How do I update the App?
                  </button>
                </h5>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#chooseMethodFAQ"
              >
                <div className="card-body">
                  Every time you make a change on your website, you don't have
                  to re - publish the app. Our plugin ensures that your
                  WooCommerce store is always in sync with the app.
                </div>
              </div>
            </div>
            <div className="card shadow-sm border-0 my-1">
              <div
                className="card-header p-0 bg-primary border-0 rounded"
                id="headingFour"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link text-decoration-none text-white"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Can I see some sample Apps?
                  </button>
                </h5>
              </div>
              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingFour"
                data-parent="#chooseMethodFAQ"
              >
                <div className="card-body">
                  <p className="mb-0">
                    HealthXP-{" "}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.xpresshopapp.net"
                      target="_blank"
                    >
                      Android
                    </a>{" "}
                    /{" "}
                    <a
                      href="https://itunes.apple.com/us/app/healthxp/id1217465867?mt=8"
                      target="_blank"
                    >
                      iOS
                    </a>
                  </p>
                  <p className="mb-0">
                    EnteNook-{" "}
                    <a
                      href="https://play.google.com/store/apps/details?id=xyz.appmaker.entebook"
                      target="_blank"
                    >
                      Android
                    </a>
                  </p>
                  <p className="mb-0">
                    KingKicks-{" "}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.kingkicks.appmaker"
                      target="_blank"
                    >
                      Android
                    </a>{" "}
                    /{" "}
                    <a
                      href="https://itunes.apple.com/us/app/kingkicks-store/id1451395588"
                      target="_blank"
                    >
                      iOS
                    </a>
                  </p>
                  <p className="mb-0">
                    Easy grocery (online preview)-{" "}
                    <a
                      href="https://appetize.io/embed/2h2jkrux4g30vk3ctc8a2yeh30?autoplay=false&orientation=portrait&deviceColor=black&scale=75&device=iphone6&osVersion=10.3"
                      target="_blank"
                    >
                      easy grocery iOS preview
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="card shadow-sm border-0 my-1">
              <div
                className="card-header p-0 bg-primary border-0 rounded"
                id="headingFive"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link text-decoration-none text-white"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Why choose us?
                  </button>
                </h5>
              </div>
              <div
                id="collapseFive"
                className="collapse"
                aria-labelledby="headingFive"
                data-parent="#chooseMethodFAQ"
              >
                <div className="card-body">
                  All our apps will have an admin panel that can be easily
                  managed by one and packs advanced features that an ecommerce
                  store should have. Appmaker has extensive WooCommerce
                  experience with customers from more than 35 countries
                </div>
              </div>
            </div>
            <div className="card shadow-sm border-0 mt-1 mb-4">
              <div
                className="card-header p-0 bg-primary border-0 rounded"
                id="headingSix"
              >
                <h5 className="mb-0">
                  <button
                    className="btn btn-link text-decoration-none text-white"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Is my theme supported?
                  </button>
                </h5>
              </div>
              <div
                id="collapseSix"
                className="collapse"
                aria-labelledby="headingSix"
                data-parent="#chooseMethodFAQ"
              >
                <div className="card-body">
                  Yes, we support every themes. Our native app is not theme
                  dependent nor a webview of your store.
                </div>
              </div>
            </div>
          </div>
          <img
            src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/04/d29ab427-appmaker-dashboard-starting.png"
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default ChooseMethod;
