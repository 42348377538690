import { data } from "jquery";
import React, { useEffect, useState } from "react";
import { getStatus, downloadApp } from "../api-call";
export default (props) => {
  const { match = {}, history } = props;
  const { params = {} } = match;
  const { appId } = params;
  const [isBuilding, setBuilding] = useState(false);
  const [buildFailed, setBuildFailed] = useState(false);
  const [downloadData, setData] = useState(undefined);
  const [moreData, setMoreData] = useState(false);
  const fetchData = async () => {
    setBuilding(true);
    const timer = setInterval(async () => {
      try {
        const response = await getStatus({ appId });
        const completeFlag =
          response &&
          response.status &&
          response.body &&
          (response.body.status === "success" ||
            response.body.status === "failed");

        if (completeFlag === true) {
          clearInterval(timer);
          if (response.body.status === "failed") {
            setBuildFailed(true);
          } else {
            const data = await downloadApp({ appId });
            if (data.status) {
              setData(data.body);
            }
          }
          setBuilding(false);
        }
        if (completeFlag) {
        }
      } catch (error) {
        clearInterval(timer);
      }
    }, 5000);
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (isBuilding) {
    return (
      <div className="loading-container">
        <img
          src="https://storage.googleapis.com/appilder_cdn/wordpress-theme/pwa-apk/images/apploader-optimised.gif"
          alt="Building"
          width="150px"
        />
        <p>Building App. Please wait...</p>
      </div>
    );
  }
  if (buildFailed) {
    return <div className="loading-container">App build failed</div>;
  }

  if (downloadData !== undefined) {
    const {
      appName,
      package_name,
      url,
      appIcon,
      keyFile,
      storePass,
      keyPass,
      keySha,
      sourceFile,
      aabFile,
      buildFile,
    } = downloadData;
    return (
      <div className="row mt-5">
        <div className="col-md-6 col-12 mx-auto bg-white shadow rounded-lg p-4 text-center">
          <img src={appIcon} alt="App Icon" width="80px" height="80px" />
          <h5 className="mb-0 mt-2">{appName}</h5>
          <small className="text-muted">
            <b>Package Name: </b>
            {package_name}
          </small>
          <div className="d-flex my-3 justify-content-center">
            <a className="btn btn-success mr-2" href={buildFile}>
              <i className="fab fa-android mr-2" />
              Download APK
            </a>
            <a className="btn btn-dark" href={aabFile}>
              <i className="fas fa-box mr-2" />
              Download App Bundle
            </a>
          </div>
          {moreData && (
            <table class="table table-sm table-striped text-left rounded mt-4">
              <tbody>
                <tr>
                  <th>Keystore File</th>
                  <td>
                    <a href={keyFile}>Download</a>
                  </td>
                </tr>
                <tr>
                  <th>Store Password</th>
                  <td>{storePass}</td>
                </tr>
                <tr>
                  <th>Key Password</th>
                  <td>{keyPass}</td>
                </tr>
              </tbody>
            </table>
          )}
          <buttton
            className="btn btn-link"
            onClick={() => setMoreData(!moreData)}
          >
            {!moreData ? "Show More" : "Show Less"}
          </buttton>
        </div>
      </div>
    );
  }
  return <div />;
};
