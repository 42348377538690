import React from "react";
import FileUpload from "./file-upload";
import { CustomInput, Alert } from "reactstrap";

export default (props) => {
  const {
    isBuilding,
    isLoading,
    enablePushNotification,
    togglePushNotification = () => {},
    appId,
    firebaseConfigFile,
    setFCMKey = () => {},
    setFirebaseConfigFile = () => {},
  } = props;
  return (
    <>
      <CustomInput
        disabled={isLoading || isBuilding}
        type="switch"
        id="push"
        name="Enable Push Notification"
        checked={enablePushNotification}
        label={"Enable Push Notification"}
        className="mr-4 cursor-pointer pb-3"
        onChange={togglePushNotification}
      />
      {enablePushNotification && (
        <>
          <div className="form-group">
            <label>
              Firebase Configuration File (
              <a href="https://appmaker.xyz/en/docs/setup-push-notifications-for-android-and-ios-apps-and-firebase-analytics/" target="_blank">
                Doc
              </a>
              )
            </label>

            <div className="preview-box mb-3">
              <FileUpload
                isLoading={isLoading}
                isBuilding={isBuilding}
                accept=".json"
                appId={appId}
                afterUpload={(data) => {
                  setFirebaseConfigFile(data.cloudStorageUrl);
                }}
              />
            </div>
            {firebaseConfigFile && (
              <Alert color="success">
                <i className="fas fa-check mr-2" />
                Firebase Config file uploaded.
              </Alert>
            )}
          </div>
          <div className="form-group">
            <input
              disabled={isLoading || isBuilding}
              type="text"
              name="url"
              required
              className="form-control"
              id="input-url"
              aria-describedby="urlhelp"
              placeholder="FCM Server Key"
              onChange={(event) => setFCMKey(event.target.value)}
            />
            <small id="urlhelp" className="form-text text-muted">
              <b>Eg</b> AAAKjMNSl:kjnasnd.....ajsbdlmn
            </small>
          </div>
        </>
      )}
    </>
  );
};
