import React, { Component } from "react";
import "./App.css";
import NavbarStart from "./components/navbar/NavbarStart";
import InitialStep from "./components/initialSteps/InitialStep";
import StepTwo from "./components/initialSteps/StepTwo";
import ChooseMethod from "./components/initialSteps/ChooseMethod";
import BookDemo from "./components/initialSteps/BookDemo";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import * as ROUTES from "./constants/routes";
import ThankYou from "./components/initialSteps/ThankYou";
import UrlValidation from "./components/initialSteps/UrlValidation";
import Webapp from './components/webapp';
import { logCurrentPage } from './utlis/analytics';

ReactGA.initialize("UA-53887153-1");


const history = createBrowserHistory();
history.listen(location => {
  logCurrentPage();
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

class App extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    ReactGA.ga(tracker => {
      window.clientId = tracker.get("clientId");
    });
  }

  render() {
    return (
      <Router history={history}>
        <div className="container pb-3">
          <NavbarStart />
          <Route path={ROUTES.INITIAL} exact component={InitialStep} />
          <Route path={ROUTES.URL_VALIDATION} exact component={UrlValidation} />
          <Route path={ROUTES.STEP_TWO} component={StepTwo} />
          <Route path={ROUTES.CHOOSE_METHOD} component={ChooseMethod} />
          <Route path={ROUTES.BOOK_DEMO} component={BookDemo} />
          <Route path={ROUTES.THANK_YOU} component={ThankYou} />
          <Route path={ROUTES.WEBAPP} component={Webapp} />
        </div>
      </Router>
    );
  }
}

export default App;
