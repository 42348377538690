import {BASE_URL} from '../../api-call';

export const checkout= (extranalRef)=>{
    if (window.TwoCoInlineCart) {
        window.TwoCoInlineCart.setup.setMerchant('102394201');
        window.TwoCoInlineCart.products.add({
          code: 'webapp-19',
          quantity: 1,
        });
        window.TwoCoInlineCart.cart.setReturnMethod({
          url: `${BASE_URL}/build/redirect2checkout?appId=${extranalRef}`,
          type: 'redirect',
        });
        window.TwoCoInlineCart.cart.setOrderExternalRef(extranalRef);
        window.TwoCoInlineCart.cart.checkout();
      }
}