import React, { Component } from "react";
import Dropzone from "react-dropzone";
import StepDots from "./components/StepDots";

class StepTwo extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-8 col-12">
          <StepDots active="step-2" />
          <div className="step-box step-box-2 bg-white rounded-lg shadow p-5 position-relative">
            <form action="">
              <div className="form-group py-4">
                <label htmlFor="app-name" className="text-secondary h4">
                  App Name
                </label>
                <input
                  type="url"
                  className="form-control"
                  id="app-name"
                  aria-describedby="appnamehelp"
                  placeholder="App name"
                />
                <small id="appnamehelp" className="form-text text-muted">
                  Some additional helping guides goes here.
                </small>
              </div>
              <div className="form-group">
                <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {/* <label htmlFor="app-logo" className="text-secondary h4">App Icon</label>
                                <div className="applogo-input">
                                    <p className="plus-icon"><i class="fas fa-plus"></i><br />Click to upload icon or Drag here</p>
                                    <input type="file" className="custom-file-input" id="app-logo" aria-describedby="logohelp" />
                                </div>
                                <small id="logohelp" className="form-text text-muted">Some additional helping guides goes here.</small> */}
              </div>
              <div className="text-right">
                <a
                  className="text-decoration-none mr-5 text-secondary"
                >
                  Cancel
                </a>
                <button type="submit" className="btn btn-primary px-5">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-4 col-12 mt-5">
          <h3 className="text-primary">Let's get started</h3>
          <p className="my-3">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias
            optio autem ipsum? Ut perferendis, illo dolorum nemo facere, magni
            mollitia laborum accusamus enim voluptates autem officiis repellat?
            Dolorem, aliquid praesentium!
          </p>
          <img
            src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/04/d29ab427-appmaker-dashboard-starting.png"
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default StepTwo;
