import React, { Component } from 'react';
import StepDots from './components/StepDots';

class ThankYou extends Component {
	state = {}
	render() {
		return (
			<div className="row">
				<div className="col-md-8 col-12">
					<StepDots active="thank-you" />
					<div className="step-box step-box-thank bg-white rounded-lg shadow p-5 position-relative">

						<div className="text-center">
							<h4 className="text-primary">Your request is registered.<br />Our App expert will contact you soon.</h4>
							<img src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/04/bd276977-mail.png" alt="Mail send" className="img-fluid py-3" />
							<p>Please check your mail for more details.</p>

							<a href="https://appmaker.xyz" className="btn btn-primary px-3 mt-4">
								Go to Homepage
            </a>
						</div>
					</div>
				</div>
				<div className="col-md-4 col-12 mt-5">
					<h5 className="text-primary">Thanks for contacting us!</h5>
					<p className="my-3">
						Your information has been forwarded to our sales team. And they will be in touch with you shortly. In case, you are looking for urgent support, You can call/message us at,<br /><a href="tel:+14152343379">+1 (415) 234-3379</a>
					</p>
					<img
						src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/04/d29ab427-appmaker-dashboard-starting.png"
						alt=""
					/>
				</div>
			</div>
		);
	}
}

export default ThankYou;