import React, { useState, useEffect } from "react";
import { CustomInput, Spinner, Badge } from "reactstrap";
import { getStatus, buildProject } from "../api-call";
import { checkout } from "./inner-components/checkout";
import AppStyles from "./inner-components/appStyles";
import ProVersion from "./proVersion";
import Generic from "./inner-components/generic";

export default (props) => {
  const { match = {}, history } = props;
  const { params = {} } = match;
  const { appId } = params;
  const [enableProVersion, setEnableProVersion] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isBuilding, setBuilding] = useState(false);
  let formEl;

  // Pro
  const [packageName, setPackageName] = useState(undefined);
  // Generic
  const [appIcon, setAppIcon] = useState(undefined);
  const [appName, setAppName] = useState(undefined);
  const [url, setUrl] = useState(undefined);
  const [splashIcon, setSplashIcon] = useState(undefined);

  // App style
  const [toolbarColor, setToolBarColor] = useState("#03A9F4");
  const [enableShowToolBar, setShowToolBar] = useState(false);
  const toggleShowToolBar = () => {
    setShowToolBar(!enableShowToolBar);
  };
  const [toolbarTitleColor, setToolBarTitleColor] = useState("#FFFFFF");
  // App ad-ons
  const [enableAdMob, setEnableAdMob] = useState(false);
  const [adMobUnitId, setAdMobUnitId] = useState(undefined);
  const [adMobAppId, setAdMobAppId] = useState(undefined);
  const toggleAdMob = () => {
    setEnableAdMob(!enableAdMob);
  };
  // Push Notification
  const [enablePushNotification, setPushNotification] = useState(false);
  const togglePushNotification = () => {
    setPushNotification(!enablePushNotification);
  };
  const [firebaseConfigFile, setFirebaseConfigFile] = useState(undefined);
  const [fcmKey, setFCMKey] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getStatus({ appId });
      const { body = {} } = response;
      let hostname = body.url
        .replace("http://", "")
        .replace("https://", "")
        .split(/[/?#]/)[0];
      setUrl(hostname);
      if (body.appIcon === undefined) {
        setAppIcon(`https://logo.clearbit.com/${hostname}`);
      } else {
        setAppIcon(body.appIcon);
      }
      if (body.splashIcon === undefined) {
        setSplashIcon(`https://logo.clearbit.com/${hostname}`);
      } else {
        setSplashIcon(body.splashIcon);
      }

      setLoading(false);
    } catch (error) {}
  };
  const buildApp = async (event) => {
    event.preventDefault();
    try {
      if (appName) {
        setBuilding(true);

        const response = await buildProject({
          appName,
          appIcon,
          appId,
          enableShowToolBar,
          toolbarColor,
          toolbarTitleColor,
          splashIcon,
        });
        if (response.status) {
          history.push(`/webapp/download/${appId}`);
        }
      } else {
       // alert("Fill the form.");
      }
    } catch (error) {}
  };
  const paymentMethod = async (event) => {
    event.preventDefault();
    try {
      if (formEl.reportValidity() && packageName && appName) {
        setBuilding(true);
        if (enableProVersion && enableAdMob && (!adMobAppId || !adMobUnitId)) {
          return alert("Complete AdMob settings");
        }
        if (
          enableProVersion &&
          enablePushNotification &&
          (!firebaseConfigFile || !fcmKey)
        ) {
          return alert("Complete Push Notification settings");
        }
        const response = await buildProject({
          appName,
          appIcon,
          appId,
          isPaymentInProgress: true,
          package_name: packageName,
          enableShowToolBar,
          toolbarColor,
          toolbarTitleColor,
          splashIcon,
          enableAdMob,
          adMobAppId,
          adMobUnitId,
          enablePushNotification,
          firebaseConfigFile,
          fcmKey,
        });
        checkout(appId);
        // setBuilding(false);
      } else {
        // alert("Fill the form");
      }
    } catch (error) {
    }
  };
  const toggleSwitch = () => setEnableProVersion(!enableProVersion);
  if (isLoading) {
    return (
      <div className="loading-container">
        <span className="d-flex mb-3">
          <Spinner type="grow" color="secondary" />
          <Spinner type="grow" color="secondary" />
          <Spinner type="grow" color="secondary" />
        </span>
        <p>Verifying URL</p>
      </div>
    );
  }
  return (
    <div className="row mt-5">
      <div className="col-md-6 col-12 mx-auto">
        <div className="bg-white rounded-lg shadow p-5 position-relative">
        <form ref={(form) => (formEl = form)} action="">

            <Generic
              isLoading={isLoading}
              isBuilding={isBuilding}
              setAppName={setAppName}
              setAppIcon={setAppIcon}
              appId={appId}
              appIcon={appIcon}
              splashIcon={splashIcon}
              setSplashIcon={setSplashIcon}
            />
            <h5>App Style</h5>
            <AppStyles
              isLoading={isLoading}
              isBuilding={isBuilding}
              enableShowToolBar={enableShowToolBar}
              toggleShowToolBar={toggleShowToolBar}
              toolbarColor={toolbarColor}
              toolbarTitleColor={toolbarTitleColor}
              setToolBarColor={setToolBarColor}
              setToolBarTitleColor={setToolBarTitleColor}
            />
            <h5>
              Pro Features{" "}
              <Badge color="success" pill>
                $19
              </Badge>
            </h5>
            <CustomInput
              disabled={isLoading || isBuilding}
              type="switch"
              id="switchBillingType"
              name="billingType"
              checked={enableProVersion}
              label={"Pro Version"}
              className="mr-4 cursor-pointer"
              onChange={toggleSwitch}
            />
            {enableProVersion && (
              <>
                <ProVersion
                  appId={appId}
                  setPackageName={setPackageName}
                  isBuilding={isBuilding}
                  isLoading={isLoading}
                  enableAdMob={enableAdMob}
                  toggleAdMob={toggleAdMob}
                  setAdMobAppId={setAdMobAppId}
                  setAdMobUnitId={setAdMobUnitId}
                  enablePushNotification={enablePushNotification}
                  togglePushNotification={togglePushNotification}
                  firebaseConfigFile={firebaseConfigFile}
                  setFCMKey={setFCMKey}
                  setFirebaseConfigFile={setFirebaseConfigFile}
                />
              </>
            )}
            <div className="text-right">
              <a
                href="https://appmaker.xyz/"
                className="text-decoration-none mr-5 text-secondary"
              >
                Cancel
              </a>
              {!enableProVersion && (
                <>
                  <button
                    disabled={isLoading || isBuilding}
                    type="submit"
                    className="btn btn-primary px-5 "
                    onClick={buildApp}
                  >
                    {!isBuilding ? "Next" : <Spinner size="sm" color="white" />}
                  </button>
                </>
              )}
              {enableProVersion && (
                <>
                  <button
                    disabled={isLoading || isBuilding}
                    type="submit"
                    className="btn btn-primary px-5 "
                    onClick={paymentMethod}
                  >
                    {!isBuilding ? (
                      "Make payment"
                    ) : (
                      <Spinner size="sm" color="white" />
                    )}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
