import React from "react";
import AdMob from "./inner-components/adMob";
import PushNotification from "./inner-components/pushNotification";
export default (props) => {
  const {
    setPackageName = () => {},
    isLoading = false,
    isBuilding = false,
    toggleAdMob,
    enableAdMob,
    setAdMobAppId,
    setAdMobUnitId,
    togglePushNotification,
    enablePushNotification,
    appId,
    firebaseConfigFile,
    setFCMKey,
    setFirebaseConfigFile,
  } = props;
  return (
    <>
      <div className="form-group">
        <input
          disabled={isLoading || isBuilding}
          type="text"
          name="package_name"
          required
          className="form-control mt-3"
          id="input-url"
          aria-describedby="packaheNameHelp"
          pattern="^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$"
          title="Enter custom package name (Eg: com.domain.app)"
          placeholder="Choose package name"
          onChange={(event) => setPackageName(event.target.value)}
        />
        <small id="packaheNameHelp" className="form-text text-muted">
          <b>Eg:</b> com.appmaker.zyisml
        </small>
      </div>
      <p className="text-dark font-weight-bold">+ADD-ONS</p>
      <AdMob
        isBuilding={isBuilding}
        isLoading={isLoading}
        toggleAdMob={toggleAdMob}
        enableAdMob={enableAdMob}
        setAdMobAppId={setAdMobAppId}
        setAdMobUnitId={setAdMobUnitId}
      />
      <PushNotification
        appId={appId}
        isBuilding={isBuilding}
        isLoading={isLoading}
        enablePushNotification={enablePushNotification}
        togglePushNotification={togglePushNotification}
        firebaseConfigFile={firebaseConfigFile}
        setFCMKey={setFCMKey}
        setFirebaseConfigFile={setFirebaseConfigFile}
      />
    </>
  );
};
