import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { uploadImage } from "../../api-call";
import { Spinner } from "reactstrap";

export default (props) => {
  const {
    label,
    appId,
    afterUpload = () => {},
    accept = "image/*",
    isBuilding,
    isLoading,
  } = props;
  const [isUploading, setUploading] = useState(false);
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    try {
      setUploading(true);
      const response = await uploadImage({
        id: appId,
        file: acceptedFiles[0],
      });
      if (response.status === true) {
        afterUpload(response);
        setUploading(false);
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      alert("File upload failed");
      setUploading(false);
    }
  };
  return (
    <>
      {!isUploading ? (
        <Dropzone
          onDrop={onDrop}
          accept={accept}
          disabled={isLoading || isBuilding || isUploading}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()} className={"dropzone box text-center"}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop files here...</p>
                ) : (
                  <div className="box_input">
                    <i className="fas fa-cloud-upload-alt uplaod_icon mr-2" />
                    <label htmlFor="file">
                      <strong>Choose a file</strong>
                      <span className="box_dragndrop"> or drag it here</span>
                    </label>
                    <br />
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
      ) : (
        <div className="text-center py-3">
          <Spinner size="sm" color="secondary" />
          Uploading...
        </div>
      )}
    </>
  );
};
