import React, { useState } from "react";
import FileUpload from "./file-upload";
import { Tooltip } from "reactstrap";

export default (props) => {
  const {
    isLoading,
    isBuilding,
    setAppName = () => {},
    setAppIcon = () => {},
    setSplashIcon = () => {},
    appId,
    appIcon,
    splashIcon,
  } = props;
  const [changeIcon, setChangeIcon] = useState(false);
  const [changeSplash, setChangeSplash] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      <div className="mb-3">
        <label htmlFor="appname">
          App Name <abbr className="text-danger">*</abbr>
        </label>
        <input
          disabled={isLoading || isBuilding}
          type="text"
          name="appname"
          required
          className="form-control"
          id="input-appname"
          aria-describedby="appnamehelp"
          placeholder="Enter your App name"
          onChange={(event) => setAppName(event.target.value)}
        />

        <small id="appnamehelp" className="form-text text-muted">
          <b>Eg:</b> Appmaker
        </small>
      </div>
      <div class="app-icon mb-3">
        <label>App Icon</label>
        <div className="preview-box">
          {!changeIcon ? (
            <>
              <img src={appIcon} width="100" height="100" alt="App Icon" />
              <button
                className="btn btn-dark btn-sm ml-4"
                onClick={() => setChangeIcon(!changeIcon)}
              >
                <i className="far fa-image mr-2" />
                Change Icon
              </button>
            </>
          ) : (
            <div className="text-center">
              <FileUpload
                isLoading={isLoading}
                isBuilding={isBuilding}
                label="Upload Icon"
                appId={appId}
                afterUpload={(data) => {
                  setAppIcon(data.cloudStoragePublicUrl);
                  setChangeIcon(!changeIcon);
                }}
              />
              <button
                className="btn btn-link py-0"
                onClick={() => setChangeIcon(!changeIcon)}
              >
                cancel
              </button>
            </div>
          )}
        </div>
        <small className="form-text text-muted">
          Recommended size: 512x512px
        </small>
      </div>

      <div class="app-splash mb-3">
        <label>
          Splash Icon{" "}
          <i className="fas fa-info-circle text-muted" id="splashHelpText" />
          <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target="splashHelpText"
            toggle={toggle}
          >
            A splash screen is a screen which appears when you open an app on
            your mobile device.
          </Tooltip>
        </label>
        <div className="preview-box">
          {!changeSplash ? (
            <>
              <img src={splashIcon} width="100" alt="App splash screen" />
              <button
                className="btn btn-dark btn-sm ml-4"
                onClick={() => setChangeSplash(!changeSplash)}
              >
                <i className="far fa-image mr-2" />
                Change Icon
              </button>
            </>
          ) : (
            <div className="text-center">
              <FileUpload
                isLoading={isLoading}
                isBuilding={isBuilding}
                label="Upload Icon"
                appId={appId}
                afterUpload={(data) => {
                  setSplashIcon(data.cloudStoragePublicUrl);
                  setChangeSplash(!changeSplash);
                }}
              />
              <button
                className="btn btn-link py-0"
                onClick={() => setChangeSplash(!changeSplash)}
              >
                cancel
              </button>
            </div>
          )}
        </div>
        <small className="form-text text-muted">
          Recommended size: 300x300px
        </small>
      </div>
    </>
  );
};
