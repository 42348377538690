import React from "react";
import ColorPicker from "./colorPicker";
import { CustomInput } from "reactstrap";

export default (props) => {
  const {
    toolbarColor,
    toolbarTitleColor,
    setToolBarColor = () => {},
    setToolBarTitleColor = () => {},
    enableShowToolBar,
    toggleShowToolBar = () => {},
    isLoading,
    isBuilding,
  } = props;
  return (
    <>
      <CustomInput
        disabled={isLoading || isBuilding}
        type="switch"
        id="toolbar"
        name="Show ToolBar"
        checked={enableShowToolBar}
        label={"Show ToolBar"}
        className="mr-4 cursor-pointer pb-3"
        onChange={toggleShowToolBar}
      />
      {enableShowToolBar && (
        <>
          <div className="d-flex">
            <ColorPicker
              value={toolbarColor}
              onChange={(data) => {
                setToolBarColor(data);
              }}
            />
            <p className="ml-2">Toolbar Color</p>
          </div>
          <div className="d-flex py-3">
            <ColorPicker
              value={toolbarTitleColor}
              onChange={(data) => {
                setToolBarTitleColor(data);
              }}
            />
            <p className="ml-2">Toolbar Title Color</p>
          </div>
        </>
      )}
    </>
  );
};
