import React from "react";
import { CustomInput } from "reactstrap";

export default (props) => {
  const {
    isLoading,
    isBuilding,
    enableAdMob,
    toggleAdMob = () => {},
    setAdMobUnitId = () => {},
    setAdMobAppId = () => {},
  } = props;
  return (
    <>
      <CustomInput
        disabled={isLoading || isBuilding}
        type="switch"
        id="admon"
        name="Enable AdMob"
        checked={enableAdMob}
        label={"Enable AdMob"}
        className="mr-4 cursor-pointer pb-3"
        onChange={toggleAdMob}
      />
      {enableAdMob && (
        <>
          <div className="form-group">
            <input
              disabled={isLoading || isBuilding}
              type="text"
              name="url"
              required
              className="form-control"
              id="input-url"
              aria-describedby="urlhelp"
              placeholder="AdMob Unit ID"
              onChange={(event) => setAdMobUnitId(event.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              disabled={isLoading || isBuilding}
              type="text"
              name="url"
              required
              className="form-control"
              id="input-url"
              aria-describedby="urlhelp"
              placeholder="AdMob App ID"
              onChange={(event) => setAdMobAppId(event.target.value)}
            />
          </div>
        </>
      )}
    </>
  );
};
