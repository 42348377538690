import React, { Component } from "react";
import StepDots from "./components/StepDots";
import ReactPhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import "react-phone-input-2/dist/style.css";
import { THANK_YOU, CHOOSE_METHOD } from "../../constants/routes";
import { withRouter } from "react-router-dom";
import storage from "local-storage-fallback";
import ReactGA from "react-ga";
import * as SETTINGS from "../../constants/Settings";
import Calendly from "./components/Calendly";

class BookDemo extends Component {
  constructor() {
    super();

    this.state = {
      sending: false,
      whatsapp_checked: false,
      call_checked: false,
      skype_checked: false,
      email_checked: false,
      name: "",
      email: "",
      client_phone: "",
      client_skype: "",
      client_type: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(e) {
    e.preventDefault();
    if (this.formEl.reportValidity()) {
      const url = storage.getItem("url");
      const formId = SETTINGS.isProduction ? "3" : "4";
      const formName = SETTINGS.isProduction
        ? "woocommercebookdemoform"
        : "testwoocommercebookdemoform";
      this.setState({ sending: true });
      let requestData = "mauticform[formId]=3&";
      const {
        name,
        email,
        client_phone,
        client_type,
        whatsapp_checked,
        call_checked,
        skype_checked,
        email_checked
      } = this.state;

      requestData += `mauticform[url]=${url}&`;
      requestData += `mauticform[f_name]=${name}&`;
      requestData += `mauticform[email]=${email}&`;
      if (whatsapp_checked)
        requestData += `mauticform[how_would_you_like_to_be][]=whatsapp&`;
      if (email_checked)
        requestData += `mauticform[how_would_you_like_to_be][]=email&`;
      if (skype_checked)
        requestData += `mauticform[how_would_you_like_to_be][]=skype&`;
      if (call_checked)
        requestData += `mauticform[how_would_you_like_to_be][]=call&`;
      requestData += `mauticform[phone]=${client_phone}&`;
      requestData += `mauticform[which_title_fits_you_the1]=${client_type}&`;
      requestData += `mauticform[formId]=${formId}&`;
      requestData += `mauticform[return]=https://crm.appmaker.xyz/form/{formId}&`;
      requestData += `mauticform[formName]=${formName}&`;
      requestData += "mauticform[messenger]=1";

      fetch("https://crm.appmaker.xyz/form/submit?formId=" + formId, {
        credentials: "include",
        method: "POST",
        body: requestData,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(dataRaw => {
          return dataRaw.text();
        })
        .then(data => {
          let jsonData = data.match(/parent\.postMessage\("(.*?)",\s'\*'\)/i);
          var decodedString = eval('"' + jsonData[1] + '"'); //eslint-disable-line
          const result = JSON.parse(decodedString);
          if (result.success) {
            this.setState({ sending: false });
            ReactGA.event({
              category: "woocommerce-create-form",
              action: "Book demo form submitted"
            });
            this.props.history.push(THANK_YOU);
          } else {
            // @todo Show validation errors
            ReactGA.event({
              category: "woocommerce-create-form",
              action: "Book demo form validation error"
            });
          }
        })
        .catch(error => {
          this.setState({ sending: false });
          console.log("request failed", error);
        });
    } else {
      ReactGA.event({
        category: "woocommerce-create-form",
        action: "Book demo form validation error"
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  handlePhoneChange = value => {
    this.setState({ client_phone: value });
  };

  handleWhatsappChange() {
    this.setState({
      whatsapp_checked: !this.state.whatsapp_checked
    });
  }
  handleSkypeChange() {
    this.setState({
      skype_checked: !this.state.skype_checked
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <StepDots active="book-demo" />

          <div className="step-box step-box-2 bg-white rounded-lg shadow position-relative">
            <Calendly />
            {/* <h4 className="text-secondary px-5 pt-5">Book an Appointment</h4>
            <form action="" ref={form => (this.formEl = form)}>
              <div className="form-group">
                <label htmlFor="app-name" className="text-secondary h6">
                  Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  className="form-control"
                  id="app-name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  autoComplete="name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="app-name" className="text-secondary h6">
                  Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  required
                  className="form-control"
                  id="app-name"
                  placeholder=""
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  autoComplete="email"
                />
              </div>
              <div className="form-group">
                <label className="text-secondary h6">
                  How would you like to be contacted?
                </label>
                <br />
                <div className="custom-control custom-checkbox py-1 px-3 rounded my-1 mr-sm-4 custom-control-inline">
                  <input
                    type="checkbox"
                    name="whatsapp_checked"
                    className="custom-control-input"
                    id="whatsapp"
                    checked={this.state.whatsapp_checked}
                    onChange={this.handleInputChange}
                  />
                  <label className="custom-control-label" htmlFor="whatsapp">
                    <i className="fab fa-whatsapp mr-2" />
                    WhatsApp
                  </label>
                </div>

                <div className="custom-control custom-checkbox py-1 px-3 rounded my-1 mr-sm-4 custom-control-inline">
                  <input
                    type="checkbox"
                    name="emailchecked"
                    className="custom-control-input"
                    id="email"
                    value={this.state.email_checked}
                    onChange={this.handleInputChange}
                  />
                  <label className="custom-control-label" htmlFor="email">
                    <i className="far fa-envelope mr-2" />
                    Email
                  </label>
                </div>
                <div className="custom-control custom-checkbox py-1 px-3 rounded my-1 mr-sm-4 custom-control-inline">
                  <input
                    type="checkbox"
                    name="skype_checked"
                    className="custom-control-input"
                    id="skype"
                    checked={this.state.skype_checked}
                    onChange={this.handleInputChange}
                  />
                  <label className="custom-control-label" htmlFor="skype">
                    <i className="fab fa-skype mr-2" />
                    Skype
                  </label>
                </div>
                <div className="custom-control custom-checkbox py-1 px-3 rounded my-1 mr-sm-4 custom-control-inline">
                  <input
                    type="checkbox"
                    name="call_checked"
                    className="custom-control-input"
                    id="call"
                    checked={this.state.call_checked}
                    onChange={this.handleInputChange}
                  />
                  <label className="custom-control-label" htmlFor="call">
                    <i className="fas fa-phone mr-2" />
                    Call
                  </label>
                </div>

                {(this.state.call_checked || this.state.whatsapp_checked) && (
                  <div className="form-group">
                    <label
                      htmlFor="phone-number"
                      className="h6 font-weight-bold"
                    >
                      Phone number
                    </label>
                    <ReactPhoneInput
                      inputExtraProps={{
                        name: "client_phone",
                        autoComplete: "phone",
                        required: true
                      }}
                      //                      defaultCountry={"us"}
                      onChange={this.handlePhoneChange}
                      value={this.state.client_phone}
                    />
                  </div>
                )}

                {this.state.skype_checked && (
                  <div className="form-group">
                    <label
                      htmlFor="phone-number"
                      className="h6 font-weight-bold"
                    >
                      Skype ID
                    </label>
                    <input
                      type="text"
                      name="client_skype"
                      value={this.state.client_skype}
                      onChange={this.handleInputChange}
                      className="form-control"
                      id="app-name"
                      placeholder=""
                    />
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="text-secondary h6">
                  Which title fits you the most?
                </label>
                <br />
                <div className="custom-control custom-radio py-1 px-3 rounded my-1 mr-sm-4 custom-control-inline">
                  <input
                    type="radio"
                    name="client_type"
                    value="owner"
                    className="custom-control-input"
                    id="website"
                    checked={this.state.client_type === "owner"}
                    onChange={this.handleInputChange}
                  />
                  <label className="custom-control-label" htmlFor="website">
                    Website owner
                  </label>
                </div>
                <div className="custom-control custom-radio py-1 px-3 rounded my-1 mr-sm-4 custom-control-inline">
                  <input
                    type="radio"
                    name="client_type"
                    value="agency"
                    className="custom-control-input"
                    id="agency"
                    checked={this.state.client_type === "agency"}
                    onChange={this.handleInputChange}
                  />
                  <label className="custom-control-label" htmlFor="agency">
                    Agency
                  </label>
                </div>
                <div className="custom-control custom-radio py-1 px-3 rounded my-1 mr-sm-4 custom-control-inline">
                  <input
                    type="radio"
                    name="client_type"
                    value="freelancer"
                    className="custom-control-input"
                    id="freelance"
                    checked={this.state.client_type === "freelancer"}
                    onChange={this.handleInputChange}
                  />
                  <label className="custom-control-label" htmlFor="freelance">
                    Freelancer
                  </label>
                </div>
              </div>

              <div className="text-right">
                <Link
                  to={CHOOSE_METHOD}
                  className="text-decoration-none mr-5 text-secondary"
                >
                  Back
                </Link>
                <button
                  type="submit"
                  disabled={this.state.sending}
                  className="btn btn-primary px-5"
                  onClick={this.submitForm}
                >
                  {this.state.sending ? "Sending" : "Send"}
                </button>
              </div>
            </form> */}
          </div>
        </div>
        {/* <div className="col-md-4 col-12 mt-5">
          <h5 className="text-primary">What happens next?</h5>
          <p className="my-3">
            You’ll talk with one of our app experts, learn all about our native
            app platforms and our app development services.
          </p>
          <h5 className="text-primary">And if I like what I see?</h5>
          <p className="my-3">
            We’ll take care of configuring your app, building it and helping you
            test it before it’s published to the App Store for you.
          </p>
          <img
            src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/04/d29ab427-appmaker-dashboard-starting.png"
            alt=""
          />
        </div> */}
      </div>
    );
  }
}

export default withRouter(BookDemo);
