import React, { useState } from "react";
import { createProject } from "../api-call";
import { Spinner } from "reactstrap";
export default (props) => {
  const [isLoading, setLoading] = useState(false);
  const [url, setUrl] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  let formEl;
  const create = async (event) => {
    event.preventDefault();
    // props.history.push('/webapp/step2/')
    setLoading(true);
    try {
      if (formEl.reportValidity() && url && email) {
        const response = await createProject({ url, email });
        if (response.status === true) {
          props.history.push(`/webapp/verification/${response.body.appId}`);
        }
      } else {
        // alert("Please fill the form");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="row mt-5">
      <div className="col-md-6 col-12 mx-auto">
        <div className="bg-white rounded-lg shadow p-5 position-relative">
          <form ref={(form) => (formEl = form)} action="">
            <div className="form-group py-1">
              <label htmlFor="input-url" className="text-dark">
                Your website URL
              </label>
              <input
                disabled={isLoading}
                type="url"
                name="url"
                required
                value={url}
                className="form-control"
                id="input-url"
                aria-describedby="urlhelp"
                placeholder="eg: https://www.mywebsite.com"
                onChange={(event) => {
                  setUrl(event.target.value);
                }}
              />
            </div>
            <div className="form-group py-1">
              <label htmlFor="emailId" className="text-dark">
                Email Id
              </label>
              <input
                type="email"
                name="email"
                required
                value={email}
                className="form-control"
                id="emailId"
                placeholder="eg: john@mail.com"
                disabled={isLoading}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </div>
            <div className="text-right mt-5">
              <a
                href="https://appmaker.xyz/woocommerce/"
                className="text-decoration-none mr-5 text-secondary"
              >
                Cancel
              </a>
              <button
                 type="submit"
                className="btn btn-primary px-5 "
                onClick={create}
                disabled={isLoading}
              >
                {!isLoading ? "Proceed" : <Spinner size="sm" color="white" />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
