import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import initialStep from "./components/initialSteps";
import appData from "./components/appConfig";
import download from "./components/download";

export default () => {
  return (
    <Router>
      <Route exact path="/webapp" component={initialStep} />
      <Route exact path="/webapp/verification/:appId" component={appData} />
      <Route exact path="/webapp/download/:appId" component={download} />
    </Router>
  );
};
