import React, { Component } from "react";
import StepDots from "./components/StepDots";
import { CHOOSE_METHOD, URL_VALIDATION } from "../../constants/routes";
import { withRouter } from "react-router-dom";
import storage from "local-storage-fallback";
import ReactGA from "react-ga";
import { trackEvent } from '../../utlis/analytics';
import * as SETTINGS from "../../constants/Settings";

class InitialStep extends Component {
  constructor() {
    super();
    this.state = {
      url: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name, value);
    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    const url = storage.getItem("url");
    if (url) {
      this.setState({ url });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.formEl.reportValidity()) {
      storage.setItem("url", this.state.url);
      ReactGA.event({
        category: "woocommerce-create-form",
        action: "Step 1 completed",
        label: "url",
        value: this.state.url,
      });

      trackEvent("create-app-url-submitted", {
        url: this.state.url
      });

      this.props.history.push(URL_VALIDATION);
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-8 col-12">
          <StepDots active="step-1" />
          <div className="step-box step-box-1 bg-white rounded-lg shadow p-5 position-relative">
            <form ref={(form) => (this.formEl = form)} action="">
              <div className="form-group py-4">
                <label htmlFor="input-url" className="text-dark">
                  URL of your Store
                </label>
                <input
                  type="url"
                  name="url"
                  required
                  className="form-control"
                  id="input-url"
                  aria-describedby="urlhelp"
                  placeholder="Enter your store url"
                  value={this.state.url}
                  onChange={this.handleInputChange}
                />
                <small id="urlhelp" className="form-text text-muted">
                  <b>Eg:</b> https://www.mystore.com
                </small>
              </div>
              <div className="text-right">
                <a
                  href="https://appmaker.xyz/woocommerce/"
                  className="text-decoration-none mr-5 text-secondary"
                >
                  Cancel
                </a>
                <button
                  type="submit"
                  className="btn btn-primary px-5 "
                  onClick={this.onSubmit}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-sm-4 col-12 mt-5">
          <h5 className="text-primary">Is my paid plugin supported?</h5>
          <p className="my-3">
            These are the{" "}
            <a href="https://appmaker.xyz/plugin-supported/" target="_blank">
              plugins
            </a>{" "}
            available in the App
          </p>
          <p className="my-3">
            Could not find your plugin here? <br />
            Our team will add support for the plugins you require.
          </p>
          <img
            src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/04/d29ab427-appmaker-dashboard-starting.png"
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default withRouter(InitialStep);
