import React, { Component } from "react";
import logo from "../../images/logo.svg";

class NavbarStart extends Component {
  render() {
    return (
      <div className="nav pt-4">
        <a href="https://appmaker.xyz">
          <img src={logo} alt="Appmaker.xyz" className="appmaker-logo" />
        </a>
      </div>
    );
  }
}

export default NavbarStart;
