import React, { Component } from "react";
import { Link } from "react-router-dom";

class StepDots extends Component {
  steps = {
    "step-1": 0,
    "validating-url": 1,
    "choose-method": 2,
    "book-demo": 3,
    "thank-you": 4
  };
  render() {
    const { active } = this.props;
    const step = this.steps[active];
    return (
      <div className="overflow-hidden">
        <ul className="nav nav-tabs border-0 nav-bullets" role="tablist">
          <li
            role="presentation"
            className={
              "position-relative " +
              (step >= this.steps["step-1"] ? "active" : "")
            }
          >
            <Link to="/" onClick={e => e.preventDefault()} />
          </li>
          <li
            role="presentation"
            className={
              "position-relative " +
              (step >= this.steps["validating-url"] ? "active" : "")
            }
          >
            <Link to="/validating-url/" onClick={e => e.preventDefault()} />
          </li>
          <li
            role="presentation"
            className={
              "position-relative " +
              (step >= this.steps["choose-method"] ? "active" : "")
            }
          >
            <Link to="/choose-method/" onClick={e => e.preventDefault()} />
          </li>
          <li
            role="presentation"
            className={
              "position-relative " +
              (step >= this.steps["book-demo"] ? "active" : "")
            }
          >
            <Link to="/book-demo/" onClick={e => e.preventDefault()} />
          </li>
          <li
            role="presentation"
            className={
              "position-relative " +
              (step >= this.steps["thank-you"] ? "active" : "")
            }
          >
            <Link to="/thank-you/" onClick={e => e.preventDefault()} />
          </li>
        </ul>
      </div>
    );
  }
}

export default StepDots;
