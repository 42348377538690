import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import StepDots from "./components/StepDots";
import storage from "local-storage-fallback";
import { Link } from "react-router-dom";
import { CHOOSE_METHOD } from "../../constants/routes";
import { trackEvent } from '../../utlis/analytics';

const UrlValidation = () => {
  const [hasError, setErrors] = useState(false);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const url = storage.getItem("url");

  useEffect(() => {
    setLoading(true);
    const validateUrl = () =>
      fetch(
        `https://staging.api.appmaker.xyz/v2/generic-utils/validate-url?url=${url}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          setResponse(res);
          trackEvent("create-app-url-validation-success", {
            url: url,
            response: res,
            hasWooCommerce: res.hasWooCommerce,
            existingProjects: res.existingProjects,
            validWp: response.validWp
          });
          if (res.hasWooCommerce && res.existingProjects === false) {
            setTimeout(() => {
              history.push(CHOOSE_METHOD);
            }, 600);
          }
        })
        .catch((err) => {
          setErrors(true);
          trackEvent("create-app-url-validation-error", {
            url: url,
            error: err.message || ''
          });
        });
    validateUrl();
  }, [url]);

  function getUserMessage(response) {
    if (
      !loading &&
      response.hasWooCommerce &&
      response.existingProjects !== false
    )
      return <AlreadyCreatedApp response={response} />;
    if (loading || response.hasWooCommerce)
      return <CheckingUrl success={response.hasWooCommerce} />;
    if (response.validWp) return <WordPressUrlDetected />;
    return <NotReachableUrl />;
  }

  return (
    <div className="row">
      <div className="col-md-8 col-12">
        <StepDots active="validating-url" />
        <div className="step-box step-box-url-validation bg-white rounded-lg shadow p-4 position-relative">
          <div className="url-validation-box bg-light p-4 rounded-lg">
            {getUserMessage(response)}
          </div>
          <div className="pt-2 text-right">
            <Link to="/" className="btn btn-link">
              Go back to edit url
            </Link>
          </div>
        </div>
      </div>
      <div className="col-sm-4 col-12 mt-5">
        <h5 className="text-primary">Is my paid plugin supported?</h5>
        <p className="my-3">
          These are the{" "}
          <a
            href="https://appmaker.xyz/plugin-supported/"
            target="_blank"
            rel="noopener noreferrer"
          >
            plugins
          </a>{" "}
          available in the App
        </p>
        <p className="my-3">
          Could not find your plugin here? <br />
          Our team will add support for the plugins you require.
        </p>
        <img
          src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/04/d29ab427-appmaker-dashboard-starting.png"
          alt=""
        />
      </div>
    </div>
  );
};

const CheckingUrl = ({ success }) => {
  if (success === true) {
    return (
      <div className="url-validation-container">
        <h5>
          <i className="fas fa-check mr-2 text-success" /> Entered URL is a
          WooCommerce Store
        </h5>
      </div>
    );
  } else {
    return (
      <div className="url-validation-container">
        <h5>
          <i className="fas fa-spinner fa-spin mr-2 text-primary" /> Checking
          URL
        </h5>
      </div>
    );
  }
};

const AlreadyCreatedApp = ({ response }) => {
  return (
    <div className="url-validation-container">
      <div className="text-center">
        <h6 className="text-dark mb-3">
          <i className="fas fa-exclamation-triangle text-primary mr-2" />
          It seems already created an app using this URL
        </h6>
        <a
          href={`https://appmaker.xyz/dashboard/apps/update/${response.existingProjects[0].id}`}
          className="btn btn-primary mt-4"
        >
          Update existing app
        </a>
        <Link to={CHOOSE_METHOD} className="btn btn-link d-block">
          Create a new app anyway
        </Link>
      </div>
    </div>
  );
};

const WordPressUrlDetected = () => {
  return (
    <React.Fragment>
      <h6 className="text-dark mb-3">
        <i className="fas fa-exclamation-triangle text-primary mr-2" />
        URL entered is a WordPress website
      </h6>
      <div className="no-gutters row">
        <div className="col-md-6 col-12 mb-1">
          <div className="bg-white px-2 py-3 mr-1 text-center rounded">
            <h2>
              <i className="fab fa-wordpress text-primary"></i>
            </h2>
            <h5>Create a WordPress specific app</h5>
            <a
              href="https://appmaker.xyz/wordpress/create?manual=1"
              className="btn btn-primary mb-3"
            >
              Create WordPress App
            </a>
            {/* <small className="text-muted">
              <b>NOTE: </b>You'll have to install{" "}
              <a
                href="https://wordpress.org/plugins/appmaker-wp-mobile-app-manager/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Appmaker WordPress
              </a>{" "}
              plugin to activate the app
  </small> */}
          </div>
        </div>
        <div className="col-md-6 col-12 mb-1">
          <div className="bg-white px-2 py-3 ml-1 text-center rounded">
            <img
              src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/04/100f5769-woocommerce_logo.png"
              className="mb-2"
              alt="woocommerce"
              height="38px"
            />
            <h5>Are you sure its a WooCommerce store?</h5>
            <Link to={CHOOSE_METHOD} className="btn btn-primary mb-3">
              Create WooCommerce App
            </Link>

            {/*  <small className="text-muted">
              <b>NOTE: </b>You'll have to install{" "}
              <a
                href="https://wordpress.org/plugins/appmaker-woocommerce-mobile-app-manager/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Appmaker WooCommerce
              </a>{" "}
              plugin to activate the app
  </small> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const NotReachableUrl = () => {
  return (
    <React.Fragment>
      <h6 className="text-dark mb-3">
        <i className="fas fa-exclamation-triangle text-warning mr-2" />
        URL entered is not recognized as a WordPress/WooCommerce website
      </h6>
      <div className="no-gutters row">
        <div className="col-md-12 col-12 mt-1">
          <div className="bg-white p-3 rounded">
            <small className="mb-2 text-muted">
              Not a wordpress website? Don't worry, we got you covered.
            </small>
            <div className="d-flex flex-wrap">
              <h2>
                <i className="fab fa-android text-secondary mr-2 my-auto"></i>
              </h2>
              <h5 className="flex-grow-1 my-auto">
                Create a webapp for your website
              </h5>
              <a
                href="https://appmaker.xyz/webapp/create"
                className="btn btn-secondary my-auto"
              >
                Create web app
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12 mb-1 mt-2">
          <div className="bg-white px-2 py-3 mr-1 text-center rounded">
            <img
              src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/04/100f5769-woocommerce_logo.png"
              className="mb-2"
              alt="woocommerce"
              height="38px"
            />
            <h5>Are you sure it is a WooCommerce store?</h5>
            <Link to={CHOOSE_METHOD} className="btn btn-primary mb-3">
              Create WooCommerce App
            </Link>
            {/* <small className="text-muted">
              <b>NOTE: </b>You'll have to install{" "}
              <a
                href="https://wordpress.org/plugins/appmaker-woocommerce-mobile-app-manager/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Appmaker WooCommerce
              </a>{" "}
              plugin to activate the app
  </small> */}
          </div>
        </div>
        <div className="col-md-6 col-12 mb-1 mt-2">
          <div className="bg-white px-2 py-3 ml-1 text-center rounded">
            <h2>
              <i className="fab fa-wordpress text-primary"></i>
            </h2>
            <h5>It is a WordPress website URL?</h5>
            <a
              href="https://appmaker.xyz/wordpress/create?manual=1"
              className="btn btn-primary mb-3"
            >
              Create WordPress App
            </a>
            {/*   <small className="text-muted">
              <b>NOTE: </b>You'll have to install{" "}
              <a
                href="https://wordpress.org/plugins/appmaker-wp-mobile-app-manager/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Appmaker WordPress
              </a>{" "}
              plugin to activate the app
            </small>*/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UrlValidation;
